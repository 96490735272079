import Col from "react-bootstrap/Col";


const Header = (props) => {
    const { user_data, user_profile_img, error } = props
    if (user_data) {
        return (
            <Col md={6} className="mx-auto text-center">
                <div className="header-title">
                    <h1>Password Reset</h1>
                    <div id="spinner" className="loader d-none"></div>
                    <h2>
                        Welcome, <b>{user_data.givenName}</b>
                    </h2>
                    {props.user_profile_img &&
                        <img id='profile-pic' src={`data:image/png;base64,${user_profile_img}`} alt="Profile" />
                    }
                </div>
            </Col>
        )
    } else {
        return (
            <Col md={6} className="mx-auto text-center">
                {error?.code &&
                    <div className="header-title">
                        <h2 className="text-danger">{`${error.code}: ${error.message}`}</h2>
                    </div>
                }
                {!error.code &&
                    <div className="header-title">
                        <h1>Password Reset</h1>
                        <div id="spinner" className="loader"></div>
                    </div>
                }
            </Col>
        )
    }
}

export default Header