import { useState, useEffect } from "react";
import Header from './components/Header'
import PasswordChangeForm from './components/PasswordChangeForm';
import { getUserByEmail } from "api/axios";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from "./authConfig";
import Container from "react-bootstrap/Container";


const App = () => {
  const [userEmail, setUserEmail] = useState("")
  const [userData, setUserData] = useState("")
  const [error, setError] = useState({})

  const isAuthenticated = useIsAuthenticated()
  const { inProgress, accounts, instance } = useMsal()

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    setTimeout(() => {
      if (accounts.length === 0) {
        instance.ssoSilent(loginRequest).then((response) => {
          setUserEmail(response.account.username)
        }).catch((error) => {
          console.error(error)
          instance.loginRedirect(loginRequest)
        })
      }
    }, 500)
  }

  useEffect(() => {
    if (accounts.length) {
      setUserEmail(accounts[0].username)
    }
  }, [accounts])

  useEffect(() => {
    if (userEmail && !userData) {
      getUserByEmail(userEmail)
        .then(data => setUserData(data.data))
        .catch(error => setError(error))
    }
  }, [userEmail, userData])


  if (userData.user_data) {
    return (
      <Container id='main_container'>
        <Header {...userData} />
        <PasswordChangeForm {...userData} />
      </Container>
    );
  } else {
    return (
      <Container id='main_container'>
        <Header error={error} />
      </Container>
    );
  }
}

export default App;
