export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_APP_ID,
        authority: `https://login.microsoftonline.com/86c94be5-5735-49dd-b5f5-abe63f8eebcf`,
        redirectUri: "/",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};
