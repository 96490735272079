import { useState } from "react"
import { ChangePasswordTPT } from "api/axios"
import { Container, Row, Col, Form } from "react-bootstrap";

function validatePassword(password){

    const isLengthMet = password.length >= 6;
    const isLowercaseMet = /[a-z]/.test(password);
    const isUppercaseMet = /[A-Z]/.test(password);

    return {isLengthMet, isLowercaseMet, isUppercaseMet};
}

const PasswordChangeForm = (props) => {
    const [userPassword, setUserPassword] = useState("")
    const [isPasswordValid, setIsPasswordValid] = useState(false)
    const [passwordValidationStatus, setPasswordValidationStatus] = useState({})
    const [userConfirmationPassword, setUserConfirmationPassword] = useState("")
    const [passwordConfirmed, setPasswordConfirmation] = useState(false)
    const [selectedSystem, setSystem] = useState(false)
    const [response, setResponse] = useState({})
    const [isLoading, setLoading] = useState(false)

    const passwordSchemaValidation = (event) => {
        const validationStatus = validatePassword(event.target.value)

        setUserPassword(event.target.value)
        setIsPasswordValid( validationStatus.isLengthMet && validationStatus.isLowercaseMet && validationStatus.isUppercaseMet )
        setPasswordValidationStatus(validationStatus)
        userConfirmationPassword === event.target.value && userConfirmationPassword && (validationStatus.isLengthMet && validationStatus.isLowercaseMet && validationStatus.isUppercaseMet) ? setPasswordConfirmation(true) : setPasswordConfirmation(false)
    }

    const passwordConfirmationValidation = (event) => {
        event.target.value === userPassword && event.target.value.length && isPasswordValid ? setPasswordConfirmation(true) : setPasswordConfirmation(false)
        setUserConfirmationPassword(event.target.value)
    }

    const updateSelectedSystem = (event) => {
        event.target.value ? setSystem(true) : setSystem(false)
    }

    const submitForm = (event) => {
        event.preventDefault()
        const system = event.target.system_selection.value

        if (system) {

            setResponse({})
            setLoading(true)
            setSystem(system)

            ChangePasswordTPT({
                userData: props.user_data,
                system: system,
                password: event.target.password_confirm.value,
                base_url: window.location.href
            })
                .then(data => setResponse(data.data.result))
                .then(() => setLoading(false))
                .catch(error => {
                    console.log(error)
                    setLoading(false)
                    setResponse(error)
                })

            setUserPassword('')
            setUserConfirmationPassword('')
            setIsPasswordValid(false)
            setPasswordValidationStatus({})
            setPasswordConfirmation(false)

            event.target.password_confirm.value = ""
            event.target.password.value = ""
        }
    }

    return (
        <Row>
            <Col md={6} className="mx-auto">
                <div className="form">
                    {(response?.status === "danger" && !isLoading) &&
                        <Container className='text-center text-wrap badge fs-6 mb-3 py-3 bg-danger'>
                            {response?.cxl_response?.MessageDetail}
                            { (response?.cxl_response?.Message) ? (response?.cxl_response?.Message) : (response?.cxl_response)  }
                        </Container>
                    }

                    {(response?.status === "success" && !isLoading) &&
                        <Container className='text-center text-wrap badge fs-6 mb-3 py-3 bg-success'>
                            {response?.cxl_response}
                        </Container>
                    }

                    {(response?.code && response?.name && !isLoading) &&
                        <Container className='text-center text-wrap badge fs-6 mb-3 py-3 bg-danger'>
                            {`${response.code}: ${response.message}`}
                        </Container>
                    }

                    {isLoading &&
                        <div id="spinner" className="loader-big"></div>
                    }

                    {(response?.status !== 'success' && !isLoading) &&
                        <Form id="main-form" onSubmit={submitForm}>
                            <Form.Group className="mb-4">
                                <Form.Select name="system_selection" onChange={updateSelectedSystem} defaultValue={selectedSystem ? selectedSystem : "Please select the system"} >
                                    <option value="">Please select the system</option>
                                    <option value="TPT">TPT</option>
                                </Form.Select>
                                <div id="system-feedback" className={selectedSystem ? "valid-feedback d-block" : "invalid-feedback d-block"}>
                                    {selectedSystem ? "System was specified" : "Please specify the system"}
                                </div>
                            </Form.Group>
                            {/* Password Input Fields Start */}
                            <Form.Group className="mb-3">
                                <Form.Control type="password" name="password" className="form-input" id="password" onInput={passwordSchemaValidation}
                                    placeholder="Password" />
                                { userPassword && (
                                    <div id="password-feedback" className="password-validation-box">
                                        <span className= {`password-validation-header ${ isPasswordValid ? "valid" : "invalid" }`}  >Your Password needs to:</span>
                                        <li className={`password-validation-content ${ passwordValidationStatus.isLengthMet ? "valid" : "invalid" }`}>Be at least 6 characters long</li>
                                        <li className={`password-validation-content ${ passwordValidationStatus.isUppercaseMet ? "valid" : "invalid" }`}>Include at least 1 upper case character</li>
                                        <li className={`password-validation-content ${ passwordValidationStatus.isLowercaseMet ? "valid" : "invalid" }`}>Include at least 1 lower case character</li>
                                    </div>
                                )  }
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control type="password" name="password_confirm" className="form-input" id="password-confirm" onInput={passwordConfirmationValidation}
                                    placeholder="Password Confirmation" />
                                { userPassword && (
                                    <div id="password-confirm-feedback" className={passwordConfirmed ? "valid-feedback d-block" : "invalid-feedback d-block"}>
                                        {passwordConfirmed ? "Passwords match" : "Passwords don't match"}
                                    </div>
                                ) }
                            </Form.Group>
                            {/* Password Input Fields End */}

                            <div className="text-center ">
                                <button type="submit" id="submit-button" disabled={!(selectedSystem && passwordConfirmed)} className="btn btn-block tx-tfm">Change Password</button>
                            </div>

                            <Container md={12}>
                                <div className="login-or">
                                    <hr className="hr-or" />
                                </div>
                            </Container>
                        </Form>
                    }
                    {response?.status === 'success' &&
                        <div className="text-center ">
                            <button id="again-button" className="btn btn-block tx-tfm" onClick={() => setResponse({})}>Reset Password Again</button>
                        </div>
                    }
                </div>
            </Col>
        </Row>
    )
}

export default PasswordChangeForm