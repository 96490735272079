import axios from "axios";


const apiURL = process.env.NODE_ENV === "development" ? "http://localhost:8000/api" : "api/"

const api = axios.create({
    baseURL: apiURL,
    headers: { 'Content-Type': 'application/json' }
});

export const getUserByEmail = async (userEmail) => await api.get('get_user_by_email', { params: { user_email: userEmail } })
export const ChangePasswordTPT = async (body) => await api.post("change_password", body)